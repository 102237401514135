const fetchTrip = async (
  tripId: string | null,
  onResponse: (data: Record<string, any> | null) => void,
) => {
  if (!tripId) onResponse(null);

  try {
    const response = await fetch(`https://go.weelet.io/api/trips/${tripId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Origin: "http://localhost:8000",
      },
    });
    const data = await response.json();

    onResponse(data.data);
  } catch (error) {
    onResponse(null);
  }
};

const fetchIfIsTripPublic = async (
  tripId: string | null,
  onResponse: (data: boolean | null) => void,
) => {
  if (!tripId) onResponse(null);

  try {
    const response = await fetch(
      `https://go.weelet.io/api/trips/${tripId}/public`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
    const data = await response.json();

    onResponse(data.data);
  } catch (error) {
    onResponse(null);
  }
};

export default {
  fetchTrip,
  fetchIfIsTripPublic,
};
