import React, { useCallback, useEffect, useState } from "react";

import { Center, Heading, useMediaQuery } from "@chakra-ui/react";

import MainLayout from "@/components/Layouts/MainLayout";
import NotFoundTripPage from "@/components/Trip/NotFoundTripPage";
import PrivateTripPage from "@/components/Trip/PrivateTripPage";
import PublicTripPage from "@/components/Trip/PublicTripPage";
import weeletioApi from "@/utils/trips";

const TripDetailPage = ({ params }: { params: Record<string, string> }) => {
  const [isLargerThanMd] = useMediaQuery("(min-width: 48em)", {
    ssr: false,
    fallback: true,
  });
  const [trip, setTrip] = useState<Record<string, any> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTripPublic, setIsTripPublic] = useState<boolean | null>(null);

  const handleTripFetching = useCallback(() => {
    weeletioApi.fetchTrip(params.id, (fetchedTrip) => {
      setTrip(fetchedTrip);
      setIsTripPublic(true);
      setIsLoading(false);
    });
  }, [params.id, setTrip, setIsLoading]);

  useEffect(() => {
    setIsLoading(true);
    weeletioApi.fetchIfIsTripPublic(params.id, (isPublic) => {
      // eslint-disable-next-line eqeqeq
      if (isPublic == null) {
        setIsLoading(false);
        return;
      }

      if (isPublic) {
        handleTripFetching();
      } else {
        setIsTripPublic(false);
        setIsLoading(false);
      }
    });
  }, []);

  if (isLoading) {
    return (
      <MainLayout isLargerThanMd={isLargerThanMd}>
        <Center height="80vh" mt={[-10, -20]}>
          <Heading fontFamily="Geomanist">
            We are loading shared trip for you.
          </Heading>
        </Center>
      </MainLayout>
    );
  }

  // eslint-disable-next-line eqeqeq
  if (trip == null && isTripPublic == null) {
    return (
      <MainLayout isLargerThanMd={isLargerThanMd}>
        <NotFoundTripPage />
      </MainLayout>
    );
  }

  return (
    <MainLayout isLargerThanMd={isLargerThanMd}>
      {isTripPublic && trip !== null && (
        <PublicTripPage trip={trip} tripId={params.id} />
      )}
      {isTripPublic === false && <PrivateTripPage tripId={params.id} />}
    </MainLayout>
  );
};

export const Head = () => (
  <>
    <meta name="robots" content="noindex,nofollow" />
    <meta name="googlebot" content="noindex,nofollow" />
  </>
);

export default TripDetailPage;
