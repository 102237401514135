import React from "react";

import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Img,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import DownloadButton from "../DownloadButton";

type Props = {
  tripId: String;
  trip: Record<string, any>;
};

const PublicTripPage = ({ tripId, trip }: Props) => (
  <Center height="80vh" mt={[0, -20]}>
    <VStack width={["90%", "50%"]} spacing={5}>
      <HStack spacing={5}>
        {/* eslint-disable-next-line eqeqeq */}
        {trip.titleImage == null || trip.titleImage.length === 0 ? (
          <Box bgColor="lightgray" rounded="md" width={150} height={150} />
        ) : (
          <Img
            src={trip.titleImage}
            alt={trip.title}
            rounded="md"
            width={150}
            height={150}
          />
        )}
        <Heading fontFamily="Geomanist">{trip.title}</Heading>
      </HStack>

      <Text
        textAlign="center"
        fontSize="xl"
        fontFamily="Geomanist"
        fontWeight="light"
      >
        If you want to see whole content of this trip, you can download this app
        by clicking the button below.
      </Text>

      <HStack>
        <Spacer />
        <DownloadButton />
        <Spacer />
      </HStack>

      <Text fontFamily="Geomanist" fontWeight="light">
        Or if you have app already installed
      </Text>

      <HStack>
        <Spacer />
        <Button
          as={Link}
          bgColor="black"
          textColor="white"
          _hover={{
            bgColor: "#5DBA77",
            shadow: "md",
            textDecoration: "none",
            textColor: "white",
          }}
          _active={{
            textDecoration: "none",
            textColor: "white",
          }}
          width={["100%", "auto"]}
          href={`weeletio://weelet.io/trips/${tripId}`}
        >
          See whole trip in app
        </Button>
        <Spacer />
      </HStack>
    </VStack>
  </Center>
);

export default PublicTripPage;
