import React from "react";

import {
  Button,
  Center,
  Heading,
  HStack,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import DownloadButton from "../DownloadButton";

const NotFoundTripPage = () => (
  <Center height="80vh" mt={[0, -20]}>
    <VStack width={["90%", "50%"]} spacing={5}>
      <Heading as="h1" textAlign="center" fontFamily="Geomanist" mb={0}>
        Unfortunately this trip was not found or does not exist. 😢
      </Heading>

      <Text
        textAlign="center"
        fontSize="xl"
        fontFamily="Geomanist"
        fontWeight="light"
      >
        If you want to see other trips or create one for yourself, you can
      </Text>

      <HStack>
        <Spacer />
        <DownloadButton />
        <Spacer />
      </HStack>

      <Text fontFamily="Geomanist" fontWeight="light">
        Or if you have app already installed
      </Text>

      <HStack>
        <Spacer />
        <Button
          as={Link}
          bgColor="black"
          textColor="white"
          _hover={{
            bgColor: "#5DBA77",
            shadow: "md",
            textDecoration: "none",
            textColor: "white",
          }}
          _active={{
            textDecoration: "none",
            textColor: "white",
          }}
          width={["100%", "auto"]}
          href="weeletio://weelet.io/trips-search"
        >
          Search for trips directly in the app
        </Button>
        <Spacer />
      </HStack>
    </VStack>
  </Center>
);

export default NotFoundTripPage;
